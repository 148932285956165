@font-face {
  font-family: 'Lato Regular';
  src: url('./assets/fonts/lato/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Lato Black';
  src: url('./assets/fonts/lato/Lato-Black.ttf');
}

@font-face {
  font-family: 'Lato BlackItalic';
  src: url('./assets/fonts/lato/Lato-BlackItalic.ttf');
}

@font-face {
  font-family: 'Lato Bold';
  src: url('./assets/fonts/lato/Lato-Bold.ttf');
}

@font-face {
  font-family: 'Lato BoldItalic';
  src: url('./assets/fonts/lato/Lato-BoldItalic.ttf');
}

@font-face {
  font-family: 'Lato Italic';
  src: url('./assets/fonts/lato/Lato-Italic.ttf');
}

@font-face {
  font-family: 'Lato Light';
  src: url('./assets/fonts/lato/Lato-Light.ttf');
}

@font-face {
  font-family: 'Lato LightItalic';
  src: url('./assets/fonts/lato/Lato-LightItalic.ttf');
}

@font-face {
  font-family: 'Lato Thin';
  src: url('./assets/fonts/lato/Lato-Thin.ttf');
}

@font-face {
  font-family: 'Lato ThinItalic';
  src: url('./assets/fonts/lato/Lato-ThinItalic.ttf');
}

body {
  box-sizing: border-box;
  font-family: ' Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.05em;
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
